<template>

    <div :id="containerId" v-if="downloaded">
        <!-- Modal Reset All -->
        <ResetAll/>
    </div>
    <div class="placeholder vh-100 w-100 pt-5" style="font-size: 20pt; color: #ffffff;" v-else>
        <div class="row">
            <div class="m-auto mt-5 text-center" style="font-family: electrolize;">
              <intersecting-circles-spinner
                      :animation-duration="1200"
                      :size="170"
                      color="#f9a901"
              />
            </div>
        </div>
    </div>

</template>


<script>
import {IntersectingCirclesSpinner} from 'epic-spinners'
import ResetAll from '@/components/Modals/ResetAll';
import eventBus from '@/plugins/event-bus';

export default {
    name: 'Game',
    components: {
        ResetAll,
        IntersectingCirclesSpinner
    },
    data() {
        return {
            downloaded: false,
            gameInstance: null,
            containerId: 'xbts-container'
        }
    },
    async mounted() {
        const game = await import('@/game/game');
        //const game = async() => import(/* webpackChunkName: "auth" */ '@/game/game');
        this.downloaded = true;
        this.$nextTick(async () => {
            this.gameInstance = await game.launch(this.containerId)
        })
    },
    async created() {

    },
    destroyed() {
        this.gameInstance.destroy(false)
    }
}
</script>


<style lang="scss">
    .placeholder {
        font-size: 2rem;
        font-family: 'electrolize';
    }

    #xbts-container {
        /*cursor: url("/cursor.png");*/
        background-color: #17172c !important;
        overflow: hidden;
        width: 100%;
        height: 100vh;
    }
</style>
