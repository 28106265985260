<template>
    <Game/>
</template>


<script>
import Game from '@/components/Game'

export default {
    name: "Login",
    components: {
        Game,
    },
    async created() {
        this.$eventBus.on('wallet', async (pin) => {
            if (pin) {
                this.$root.pin = pin;
                await this.$router.push('/accounts');
            }
        })
    }
}
</script>

<style lang="scss">
    body {
        height: 100%;
    }
</style>
