<template>
    <div>
        <modal ref="modalReset" id="mReset" class="bg-dark"
               headerClasses="justify-content-center"
               name="modalReset"
        >
            <div class="card">
                <div class="card-body vh-100">
                    <h4 class="text-center">{{$t('app.sure')}}</h4>
                    <p>{{$t('app.all_data_reset')}}</p>

                    <div class="card-footer">
                        <div class="row">
                            <div  class="col-md-6 col-sm-6 col-xs-6 mb-2">
                                <b-button @click="onResetData" class="text-uppercase w-100" variant="danger">{{$t('app.confirm')}}</b-button>
                            </div>
                            <div  class="col-md-6 col-sm-6 col-xs-6 mb-2">
                                <b-button
                                        @click="hide"
                                        class="text-uppercase w-100"
                                        variant="primary"
                                >{{$t('app.cancel')}}
                                </b-button>
                            </div>
                        </div>


                    </div>
                </div>


            </div>
        </modal>
    </div>
</template>

<script>
import eventBus from '@/plugins/event-bus';


export default {
    name: "ModalReset",
    components: {

    },
    data() {
        return {
            show: false,
        }
    },
    watch: {
        show(value) {
            //value ? eventBus.emit('overlay:show') : eventBus.emit('overlay:hide');
        }
    },
    methods: {
        async hide() {
            this.show = false;
            this.$modal.hide('modalReset');
        },
        async onResetData() {
            await this.$store.dispatch('app/reset');
            await this.$store.dispatch('accounts/reset');
            await this.$store.dispatch('resetData');
            //eventBus.emit('modal:onReset');

            this.show = false;
            //await this.$router.push({path: '/game'});
            document.location.reload();
        }
    },
    async created() {
        eventBus.on('modal:reset', async () => {
            this.show = true;
            this.$modal.show('modalReset');
        });

    }
}
</script>

<style scoped>

</style>
